import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

const NotFoundPage: React.FunctionComponent<Record<string, unknown>> = () => {
    return (
        <Layout>
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
            <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
        </Layout>
    );
};

export default NotFoundPage;
